import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/Contact.css';

function Contact() {
  const form = useRef();
  const [sent, setSent] = useState(false); // State to manage the email sent confirmation
  const [sending, setSending] = useState(false); // State to manage the sending state

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(true); // Set sending to true when the email is being sent

    emailjs.sendForm('service_6fx4pig', 'template_9k5ludu', form.current, 'pfziTxB48o5XS7CLH')
      .then((result) => {
          console.log(result.text);
          setSent(true); // Set sent to true when the email is sent successfully
          setSending(false); // Reset sending to false after email is sent
          // Optionally reset your form here
          form.current.reset();
      }, (error) => {
          console.log(error.text);
          setSent(false); // Set sent to false if there was an error
          setSending(false); // Reset sending to false if there was an error
      });
  };

  return (
    <div className="contact-section">
      <div className="contact-content">
        <div className="contact-info">
          <h2>Contact Me</h2>
          <p>Always available for getting in touch. Feel free to contact me.</p>
          <p>Dublin, Ireland</p>
          <p>ronanglennon03@gmail.com</p>
          <p>(353) 83 845 7203</p>
        </div>
        <form ref={form} onSubmit={sendEmail} className="contact-form">
          <input type="text" name="user_name" placeholder="Name" />
          <input type="email" name="user_email" placeholder="Email" />
          <input type="text" name="subject" placeholder="Subject" />
          <textarea name="message" placeholder="Your Message" rows="4"></textarea>
          <button type="submit" disabled={sending}>Send Message</button>
          {sending && <p>Sending your message...</p>}
          {sent && <p>Your message has been sent successfully!</p>}
        </form>
      </div>
    </div>
  );
}

export default Contact;
