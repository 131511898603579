import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Projects.css';


// Custom arrow components
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
    />
  );
}

function Projects() {
    const settings = {
      // Updated settings
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      adaptiveHeight: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

  return (
    <div className="projects-section">
      <h1>My Projects</h1>
      <h3>Here are some of the projects I have completed in my career</h3>
      <Slider {...settings}>
        {/* Slider items */}
        <div className="project-item">
          <video
            loop
            autoPlay
            muted
            playsInline
            src="/java-game-video.mp4" // Path to your video file
          >
            Your browser does not support the video tag.
          </video>
          <div className="project-info">
            <h3 className="project-title">JavaFX Game</h3>
            <p className="project-description">A classic Asteroids! arcade space shooter game developed in Java.</p>
          </div>
        </div>
        <div className="project-item">
          <video
            loop
            autoPlay
            muted
            playsInline
            src="/NYSeeNowProject.mp4" // Adjust with the correct path to your video
          >
            Your browser does not support the video tag.
          </video>
          <div className="project-info">
            <h3 className="project-title">NYSeeNow - Travel Itinerary App</h3>
            <p className="project-description">New York City Travel Itinerary and 'Busyness' Predictor</p> 
          </div>
        </div>

        <div className="project-item">
          <video
            loop
            autoPlay
            muted
            playsInline
            src="/VR_Jam_Room.mp4" // Path to your video file
          >
            Your browser does not support the video tag.
          </video>
          <div className="project-info">
            <h3 className="project-title">VR Jam Room</h3>
            <p className="project-description">I made my own music jam room in VR! I built a 3D model of my keyboard and made it playable in virtual reality.</p>
          </div>
        </div>
        {/* ... add more project items as needed */}
        <div className="project-item">
          <video
            loop
            autoPlay
            muted
            playsInline
            src="/dublin-bikes.mp4" // Path to your video file
          >
            Your browser does not support the video tag.
          </video>
          <div className="project-info">
            <h3 className="project-title">Dublin Bikes Finder</h3>
            <p className="project-description">Web Application designed to find the nearest bike station in the city based on the occupancy of each station.</p>
          </div>
        </div>
      </Slider>
      <img src="projects-avatar.png" alt="Avatar" className="projects-avatar" />
    </div>
  );
}

export default Projects;