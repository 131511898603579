import React from 'react';
import '../styles/Skills.css'; // Ensure you have the correct path to your CSS file

function Skills() {
  return (
    <div className="skills-section">
      <div className="skills-block education">
        <h2>Education</h2>
        <p>MSc Computer Science (Conv) - University College Dublin, 2023</p>
        <p>Higher Diploma Computer Science (Conv) - University College Dublin, 2022 – 2023</p>
        <p>Bachelor of Arts (Hons) Music & Philosophy - University College Dublin, 2018 – 2021</p>
        <p>PLC Associate Degree in Graphic Design - Dublin Institute of Design, 2017 – 2018</p>
      </div>
      <div className="skills-block experience">
        <h2>Experience</h2>
        <p>Professional Musician and Sound Engineer - 2012 – Present</p>
        <p>Freebird Records Store - Associate, 2019 – 2023</p>
        <p>Schuh Dundrum - Sales Advisor, 2018 – 2021</p>
      </div>
      <div className="skills-block skills">
        <h2>Skills</h2>
        <p>Languages: React, JavaScript, HTML5, CSS3, Java, Python</p>
        <p>Cloud Computing: AWS services including EC2, S3, RDS, Redshift</p>
        <p>Web Development: Responsive web applications, UI design using React</p>
        <p>Version Control: Git</p>
      </div>
      <img src="skills-avatar.png" alt="Avatar" className="skills-avatar" />
    </div>
  );
}

export default Skills;
