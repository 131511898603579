// HamburgerMenu.js
import React from 'react';
import '../styles/HamburgerMenu.css'; // Update with your correct CSS path

function HamburgerMenu({ toggleSidebar, isSidebarOpen }) {
  // Determine the class for the menu based on whether the sidebar is open
  const menuClasses = `hamburger-menu ${isSidebarOpen ? 'open-sidebar' : ''}`;

  return (
    <button className={menuClasses} onClick={toggleSidebar}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
}

export default HamburgerMenu;
