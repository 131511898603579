import React from 'react';
import '../styles/About.css';

function About() {
  return (
    <section className="about-section">
      <div className="about-panel">
        <div className="about-text">
          <h1>Ronan Glennon</h1>
          <p className="about-education">MSc Computer Science, BA Arts & Humanities</p>
          <p className="about-description">
            Computer Science graduate with a foundation in critical thinking & creative problem solving,
            actively exploring developer roles and eager to contribute to any challenge ahead.
          </p>
          <div className="social-icons">
            <a href="https://www.linkedin.com/in/ronan-glennon333/" className="social-icon" aria-label="LinkedIn">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://github.com/Ronan-G3" className="social-icon" aria-label="GitHub">
              <i className="fab fa-github"></i>
            </a>
            {/* More icons can be added here */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
