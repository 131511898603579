import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import HamburgerMenu from './HamburgerMenu';
import '../styles/Navbar.css';

function Navbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.7 } // This threshold can be adjusted
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Function to apply color based on the active section
  const getMenuColor = () => {
    switch (activeSection) {
      case 'home':
      case 'projects':
      case 'contact':
        return 'black';
      default:
        return 'white';
    }
  };

  return (
    <>
      <nav className={`navbar ${getMenuColor() === 'white' ? 'white-menu' : 'black-menu'}`}>
        <a href="/" className="logo">Ronan Glennon</a>
        <HamburgerMenu toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      </nav>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </>
  );
}

export default Navbar;
