import React from 'react';
import '../styles/Sidebar.css';

function Sidebar({ isOpen, toggleSidebar }) {
  const handleNavigation = (e, sectionId) => {
    e.preventDefault();
    // Close the sidebar before scrolling
    toggleSidebar();
    // Use setTimeout to ensure the sidebar is closed before scrolling
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.error(`No element with ID ${sectionId} found.`);
      }
    }, 300); // Adjust timing if needed
  };

  return (
    <aside className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      {/* ... */}
      <nav className="sidebar-nav">
        <a href="#home" onClick={(e) => handleNavigation(e, 'home')}>Home</a>
        <a href="#about" onClick={(e) => handleNavigation(e, 'about')}>About</a>
        <a href="#projects" onClick={(e) => handleNavigation(e, 'projects')}>Projects</a>
        <a href="#skills" onClick={(e) => handleNavigation(e, 'skills')}>Skills</a>
        <a href="#contact" onClick={(e) => handleNavigation(e, 'contact')}>Contact</a>
      </nav>
      {/* ... */}
    </aside>
  );
}

export default Sidebar;
